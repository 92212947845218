import React, { useContext, useEffect, useState, useRef } from 'react';
import {
  Modal,
  Form,
  Input,
  Upload,
  Button,
  Icon,
  Select,
  DatePicker,
  Checkbox
} from 'antd';

import { CampaignsContext } from '../../contexts/CampaignsContext';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea, Group } = Input;

const CreateCampaignForm = props => {
  const {
    cities,
    countries,
    communities,
    redeemLocations,
    merchants,
    createCampaign,
    response,
    setResponse,
    benefit,
    benefits,
    setBenefits,
    addBenefit,
    deleteBenefit,
    updateBenefit,
    isEmptyBenefit,
    asyncForEach
  } = useContext(CampaignsContext);

  const { modalVisible, setModalVisible, modal } = props;
  const [validationError, setValidationError] = useState({});
  const [loading, setLoading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [gallery, setGallery] = useState([]);
  const [descCharCount, setDescCharCount] = useState(0);
  const [shortDescCharCount, setShortDescCharCount] = useState(0);
  const [selectedCountries, setSelectedCountries] = useState({});
  const [selectedCities, setSelectedCities] = useState({});
  const descInput = useRef(null);
  const shortDescInput = useRef(null);

  const { getFieldDecorator, getFieldError, setFieldsValue } = props.form;

  const nameError = getFieldError('name') || validationError.name;
  const rewardPointsError =
    getFieldError('reward_points') || validationError.reward_points;
  const shortDescriptionError =
    getFieldError('short_description') || validationError.short_description;
  const descriptionError =
    getFieldError('description') || validationError.description;
  const conditionsError =
    getFieldError('conditions') || validationError.conditions;
  const discountError = getFieldError('discount') || validationError.discount;
  const merchantError =
    getFieldError('merchant_id') || validationError.merchant_id;
  const dateError = getFieldError('date') || validationError.date;
  const showEndDateError =
    getFieldError('show_end_date') || validationError.show_end_date;
  const featuredError = getFieldError('featured') || validationError.featured;
  const freemiumError = getFieldError('freemium') || validationError.freemium;
  const citiesError = getFieldError('city_ids') || validationError.city_ids;
  const countriesError =
    getFieldError('country_ids') || validationError.country_ids;
  const communitiesError =
    getFieldError('community_ids') || validationError.community_ids;
  const redeemLocationsError =
    getFieldError('redeem_locations_ids') ||
    validationError.redeem_locations_ids;
  const imageError = getFieldError('image') || validationError.image;
  const galleryError = getFieldError('gallery') || validationError.gallery;

  const closeModal = () => {
    setValidationError({});
    setModalVisible(false);
    props.form.resetFields();
  };

  const handleOk = () => {
    setLoading(true);

    props.form.validateFields(async (error, fieldsValue) => {
      if (error) {
        setLoading(false);
        return;
      }

      const { date, ...noDate } = fieldsValue;
      let values;

      if (fieldsValue.date) {
        values = {
          ...noDate,
          date_start: fieldsValue.date[0].format('YYYY-MM-DD HH:mm:ss'),
          date_end: fieldsValue.date[1].format('YYYY-MM-DD HH:mm:ss')
        };
      } else {
        values = {
          ...noDate
        };
      }

      // return if there are errors on any benefit
      if (benefits.some(benefit => benefit.error)) return;

      let filledBenefits = benefits.filter(benefit => !isEmptyBenefit(benefit));

      if (filledBenefits) {
        values.benefits = filledBenefits;
      }

      let medias = [];

      await (async () => {
        await asyncForEach(gallery, async file => {
          medias.push(await getBase64(file.originFileObj));
        });
      })();

      await createCampaign({ ...values, medias });
      setGallery([]);

      setBenefits([{ ...benefit }]);
    });
  };

  const handleCancel = () => {
    setResponse({});
    setLoading(false);
    setGallery([]);
    closeModal();
    setBenefits([{ ...benefit }]);
  };

  const handleDescription = e => {
    setDescCharCount(e.target.value.length);

    const currentValidation = { ...validationError };

    if (e.file) {
      currentValidation.image = undefined;
    } else {
      const eventTarget = e.target;

      if (eventTarget) {
        const fieldName = eventTarget.name;
        currentValidation[fieldName] = undefined;
      }
    }

    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  const handleShortDescription = e => {
    setShortDescCharCount(e.target.value.length);

    const currentValidation = { ...validationError };

    if (e.file) {
      currentValidation.image = undefined;
    } else {
      const eventTarget = e.target;

      if (eventTarget) {
        const fieldName = eventTarget.name;
        currentValidation[fieldName] = undefined;
      }
    }

    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  const handleDate = () => {
    const currentValidation = { ...validationError };

    currentValidation.date = undefined;

    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  const handleChange = async e => {
    const currentValidation = { ...validationError };

    if (e.file) {
      currentValidation.image = undefined;
    } else {
      const eventTarget = e.target;

      if (eventTarget) {
        const fieldName = eventTarget.name;
        currentValidation[fieldName] = undefined;
      }
    }

    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  const handleCities = e => {
    const currentValidation = { ...validationError };

    currentValidation.city_ids = undefined;

    if (validationError) {
      setValidationError(currentValidation);
    }

    const selectedCitiesList = [];
    const filteredCountriesList = [];

    e.map(id => {
      const selectedCity = cities.find(city => {
        return city.id === id;
      });

      return selectedCitiesList.push(selectedCity);
    });

    selectedCitiesList.map(city => {
      if (!filteredCountriesList.includes(city.country_id)) {
        return filteredCountriesList.push(city.country_id);
      }
      return null;
    });

    setFieldsValue({
      country_ids: filteredCountriesList
    });
  };

  const handleCountries = e => {
    const currentValidation = { ...validationError };

    currentValidation.country_ids = undefined;

    if (validationError) {
      setValidationError(currentValidation);
    }

    const filteredCountries = e.map(selection => {
      return countries.find(country => {
        return selection === country.id;
      });
    });

    if (filteredCountries.length > 0) {
      setSelectedCountries(filteredCountries);
    } else {
      setSelectedCountries(countries);
    }
  };

  const handleCommunities = () => {
    const currentValidation = { ...validationError };

    currentValidation.community_ids = undefined;

    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  const handleRedeemLocations = () => {
    const currentValidation = { ...validationError };

    currentValidation.redeem_locations_ids = undefined;

    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  const handleMerchants = () => {
    const currentValidation = { ...validationError };

    currentValidation.merchant_id = undefined;

    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  const handleGalleryCancel = () => setPreviewVisible(false);

  const handleGalleryPreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };
  const handleGalleryChange = ({ fileList }) => {
    setGallery([...fileList]);
  };

  useEffect(() => {
    if (modal === 'create') {
      if (response.errors) {
        setValidationError(response.errors);
      } else {
        setValidationError({});
      }

      if (
        response.requestFor === 'create' &&
        response.requestInProgress === false &&
        response.id
      ) {
        closeModal();
      }

      if (
        response.requestFor === 'create' &&
        response.requestInProgress === false
      ) {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  useEffect(() => {
    setShortDescCharCount(0);
    setDescCharCount(0);
  }, [modalVisible]);

  useEffect(() => {
    if (selectedCountries.length > 0) {
      let filteredCities = [];
      selectedCountries.map(selection => {
        let filteredCountries = cities.filter(city => {
          return city.country_id === selection.id;
        });

        filteredCountries.map(country => {
          return filteredCities.push(country);
        });

        setSelectedCities(filteredCities);

        return filteredCountries;
      });
    } else {
      setSelectedCities(cities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountries]);

  useEffect(() => {
    if (selectedCities.length === 0) {
      setSelectedCities(cities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cities]);

  if (modal === 'create') {
    return (
      <Modal
        title={`Add new campaign`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        bodyStyle={{ maxHeight: '50vh', overflowY: 'scroll' }}
        confirmLoading={loading}
      >
        <Form layout="vertical" onSubmit={handleOk}>
          <Form.Item
            label="Name"
            validateStatus={nameError ? 'error' : ''}
            help={nameError || ''}
          >
            {getFieldDecorator('name', {
              rules: [
                { required: true, message: 'Please input a campaign name!' }
              ],
              onChange: e => handleChange(e)
            })(<Input name="name" placeholder="Name" />)}
          </Form.Item>
          <Form.Item
            label="Reward points"
            validateStatus={rewardPointsError ? 'error' : ''}
            help={rewardPointsError || ''}
          >
            {getFieldDecorator('reward_points', {
              rules: [
                {
                  pattern: /^\d*$/,
                  message: 'Please input a number!'
                }
              ],
              onChange: e => handleChange(e)
            })(<Input name="reward_points" placeholder="Reward points" />)}
          </Form.Item>
          <Form.Item
            label={`Short description ${shortDescCharCount}/60`}
            validateStatus={shortDescriptionError ? 'error' : ''}
            help={shortDescriptionError || ''}
          >
            {getFieldDecorator('short_description', {
              rules: [
                {
                  required: true,
                  message: 'Please enter a short description!'
                }
              ],
              onChange: e => handleShortDescription(e)
            })(
              <TextArea
                ref={shortDescInput}
                autoSize={true}
                name="short_description"
                placeholder="Short description"
                maxLength={60}
              />
            )}
          </Form.Item>
          <Form.Item
            label={`Description ${descCharCount}/∞`}
            validateStatus={descriptionError ? 'error' : ''}
            help={descriptionError || ''}
          >
            {getFieldDecorator('description', {
              onChange: e => handleDescription(e)
            })(
              <TextArea
                ref={descInput}
                autoSize={true}
                name="description"
                placeholder="Description"
              />
            )}
          </Form.Item>
          <Form.Item
            label="Conditions"
            validateStatus={conditionsError ? 'error' : ''}
            help={conditionsError || ''}
          >
            {getFieldDecorator('conditions', {
              onChange: e => handleChange(e)
            })(
              <TextArea
                autoSize={true}
                name="conditions"
                placeholder="Conditions"
              />
            )}
          </Form.Item>
          <Form.Item
            label="Discount"
            validateStatus={discountError ? 'error' : ''}
            help={discountError || ''}
          >
            {getFieldDecorator('discount', {
              rules: [
                {
                  pattern: /^\d*$/,
                  message: 'Please input a number!'
                }
              ],
              onChange: e => handleChange(e)
            })(<Input name="discount" placeholder="Discount" />)}
          </Form.Item>
          <Form.Item
            label="Date"
            validateStatus={dateError ? 'error' : ''}
            help={dateError || ''}
          >
            {getFieldDecorator('date', {
              onChange: () => handleDate()
            })(<RangePicker showTime format="YYYY-MM-DD HH:mm:ss" />)}
          </Form.Item>
          <div style={{ display: 'flex' }}>
            <Form.Item
              validateStatus={featuredError ? 'error' : ''}
              help={featuredError || ''}
            >
              {getFieldDecorator(
                'featured',
                {}
              )(
                <p>
                  <Checkbox name="featured">Featured</Checkbox>
                </p>
              )}
            </Form.Item>
            <Form.Item
              validateStatus={showEndDateError ? 'error' : ''}
              help={showEndDateError || ''}
            >
              {getFieldDecorator(
                'show_end_date',
                {}
              )(
                <p>
                  <Checkbox name="show_end_date">Show end date</Checkbox>
                </p>
              )}
            </Form.Item>
            <Form.Item
              validateStatus={freemiumError ? 'error' : ''}
              help={freemiumError || ''}
            >
              {getFieldDecorator('freemium')(
                <p>
                  <Checkbox name="freemium">Freemium</Checkbox>
                </p>
              )}
            </Form.Item>
          </div>

          <div style={{ marginBottom: '32px' }}>
            <h3>Benefits</h3>
            {benefits.map((benefit, i) => {
              return (
                <Form.Item
                  validateStatus={benefit.error ? 'error' : ''}
                  help={benefit.error ? 'All fields are required' : ''}
                  style={{ marginBottom: '16px' }}
                  key={i}
                >
                  <Group compact>
                    <Input
                      name={`benefits[${i}}].title`}
                      placeholder="Title"
                      style={{ width: '25%' }}
                      onChange={e => {
                        updateBenefit(e, i, 'title');
                      }}
                      value={benefit.title}
                    />
                    <Input
                      name={`benefits[${i}}].price_before`}
                      placeholder="Price before"
                      style={{ width: '25%' }}
                      onChange={e => {
                        updateBenefit(e, i, 'price_before');
                      }}
                      value={benefit.price_before}
                    />
                    <Input
                      name={`benefits[${i}}].price_after`}
                      placeholder="Price after"
                      style={{ width: '25%' }}
                      onChange={e => {
                        updateBenefit(e, i, 'price_after');
                      }}
                      value={benefit.price_after}
                    />
                    <Button
                      style={{ width: '12.5%' }}
                      onClick={() => {
                        addBenefit(i);
                      }}
                      type="primary"
                    >
                      <Icon type="plus" />
                    </Button>
                    {benefits.length > 1 ? (
                      <Button
                        style={{ width: '12.5%' }}
                        onClick={() => {
                          deleteBenefit(i);
                        }}
                        type="danger"
                      >
                        <Icon type="delete" />
                      </Button>
                    ) : (
                      ''
                    )}
                  </Group>
                </Form.Item>
              );
            })}
          </div>

          <Form.Item
            label="Merchant"
            validateStatus={merchantError ? 'error' : ''}
            help={merchantError || ''}
          >
            {getFieldDecorator('merchant_id', {
              rules: [{ required: true, message: 'Please select a merchant!' }],
              onChange: () => handleMerchants()
            })(
              <Select
                name="merchant_id"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Merchant"
              >
                {merchants.map(merchant => {
                  return (
                    <Option key={merchant.id} value={merchant.id}>
                      {`${merchant.name} (${merchant.address})`}
                    </Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
          <Form.Item
            label="Cities"
            validateStatus={citiesError ? 'error' : ''}
            help={citiesError || ''}
          >
            {getFieldDecorator('city_ids', {
              onChange: e => handleCities(e)
            })(
              <Select
                name="city_ids"
                mode="multiple"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                placeholder="City"
              >
                {selectedCities.map(city => {
                  return (
                    <Option key={city.id} value={city.id}>
                      {`${city.name} (${city.postal_code})`}
                    </Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
          <Form.Item
            label="Countries"
            validateStatus={countriesError ? 'error' : ''}
            help={countriesError || ''}
          >
            {getFieldDecorator('country_ids', {
              onChange: e => handleCountries(e)
            })(
              <Select
                name="country_ids"
                mode="multiple"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Country"
              >
                {countries.map(country => {
                  return (
                    <Option key={country.id} value={country.id}>
                      {`${country.name} (${country.iso})`}
                    </Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
          <Form.Item
            label="Communities"
            validateStatus={communitiesError ? 'error' : ''}
            help={communitiesError || ''}
          >
            {getFieldDecorator('community_ids', {
              rules: [
                { required: true, message: 'Please select a community!' }
              ],
              onChange: () => handleCommunities()
            })(
              <Select
                name="community_ids"
                mode="multiple"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Community"
              >
                {communities.map(community => {
                  return (
                    <Option key={community.id} value={community.id}>
                      {community.name}
                    </Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
          <Form.Item
            label="Redeem locations"
            validateStatus={redeemLocationsError ? 'error' : ''}
            help={redeemLocationsError || ''}
          >
            {getFieldDecorator('redeem_locations_ids', {
              onChange: () => handleRedeemLocations()
            })(
              <Select
                name="redeem_locations_id"
                mode="multiple"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Redeem location"
              >
                {redeemLocations.map(redeemLocation => {
                  return (
                    <Option key={redeemLocation.id} value={redeemLocation.id}>
                      {redeemLocation.name}
                    </Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
          <Form.Item
            label="Image"
            validateStatus={imageError ? 'error' : ''}
            help={imageError || ''}
          >
            {getFieldDecorator('image', {
              valuePropName: 'fileList',
              getValueFromEvent: normFile,
              onChange: e => handleChange(e)
            })(
              <Upload name="image" beforeUpload={() => false}>
                <Button>
                  <Icon type="upload" /> Click to upload image
                </Button>
              </Upload>
            )}
          </Form.Item>
          <Form.Item
            validateStatus={galleryError ? 'error' : ''}
            help={galleryError || ''}
            label="Gallery"
          >
            <div className="clearfix">
              <Upload
                beforeUpload={() => false}
                listType="picture-card"
                fileList={gallery}
                onPreview={handleGalleryPreview}
                onChange={handleGalleryChange}
              >
                <div>
                  <Icon type="plus" />
                  <div className="ant-upload-text">Upload</div>
                </div>
              </Upload>
              <Modal
                visible={previewVisible}
                footer={null}
                onCancel={handleGalleryCancel}
              >
                <img
                  alt="example"
                  style={{ width: '100%' }}
                  src={previewImage}
                />
              </Modal>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    );
  } else {
    return null;
  }
};

const CreateCampaign = Form.create()(CreateCampaignForm);

export default CreateCampaign;
