import React, { useState, useEffect, createContext, useContext } from 'react';
import { AwsClient } from 'aws4fetch';

import { AuthContext } from './AuthContext';
import { PAYMENT_ENDPOINT } from '../consts/globals';

export const InvoicesContext = createContext();

const InvoicesContextProvider = props => {
  const { creds } = useContext(AuthContext);
  const [invoices, setInvoices] = useState([]);
  const [offers, setOffers] = useState([]);
  const [response, setResponse] = useState({});

  const abortController = new AbortController();
  const signal = abortController.signal;

  const aws = new AwsClient({
    accessKeyId: creds.accessKeyId,
    secretAccessKey: creds.secretAccessKey,
    sessionToken: creds.sessionToken
  });

  const getInvoices = async () => {
    try {
      const response = await aws.fetch(`${PAYMENT_ENDPOINT}/invoices`, {
        signal: signal,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        }
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      const invoices = responseJson.data.filter(invoice => {
        return invoice.invoice_number;
      });

      const offers = responseJson.data.filter(offer => {
        return offer.offer_number;
      });

      setInvoices(invoices);
      setOffers(offers);
    } catch (error) {
      console.log(error);
    }
  };

  const getPDF = async (type, id) => {
    try {
      const response = await aws.fetch(
        `${PAYMENT_ENDPOINT}/invoices/${id}/${type}-pdf`,
        {
          signal: signal,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          },
          responseType: 'blob'
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.blob();

      //Create a Blob from the PDF Stream
      const file = new Blob([responseJson], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);

      return fileURL;
    } catch (error) {
      console.log(error);
    }
  };

  const viewPDF = async (type, id) => {
    const url = await getPDF(type, id);
    window.open(url);
  };

  const downloadPDF = async (type, id) => {
    const url = await getPDF(type, id);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${id}`);
    link.click();
  };

  useEffect(() => {
    getInvoices();

    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InvoicesContext.Provider
      value={{
        invoices,
        offers,
        getInvoices,
        viewPDF,
        downloadPDF,
        response,
        setResponse
      }}
    >
      {props.children}
    </InvoicesContext.Provider>
  );
};

export default InvoicesContextProvider;
