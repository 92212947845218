import React, { useContext, useState, Fragment } from 'react';
import styled from 'styled-components';
import { Table, Icon, Button, Divider } from 'antd';

import { RedeemLocationsContext } from '../../contexts/RedeemLocationsContext';
import CreateRedeemLocation from './CreateRedeemLocation';
import EditRedeemLocation from './EditRedeemLocation';
import DeleteRedeemLocation from './DeleteRedeemLocation';

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

const StyledButton = styled(Button)`
  margin-right: 0.5rem;
`;

const StyledIcon = styled.span`
  cursor: pointer;
`;

const StyledSpan = styled.span`
  color: #1890ff;
  cursor: pointer;
`;

const RedeemLocationsList = () => {
  const { transformedRedeemLocations, viewQR, downloadQR } = useContext(
    RedeemLocationsContext
  );
  const [modalVisible, setModalVisible] = useState(false);
  const [modal, setModal] = useState('');
  const [selectedRedeemLocation, setSelectedRedeemLocation] = useState({});

  const redeemLocations = transformedRedeemLocations.map(redeemLocation => {
    const redeemLocationName = redeemLocation.name
      .split(' ')
      .map(w => w.substring(0, 1).toUpperCase() + w.substring(1))
      .join(' ');

    let redeemLocationAddress;

    if (redeemLocation.address) {
      redeemLocationAddress = redeemLocation.address
        .split(' ')
        .map(w => w.substring(0, 1).toUpperCase() + w.substring(1))
        .join(' ');
    }

    let transformedRedeemLocation = {
      ...redeemLocation,
      name: redeemLocationName,
      address: redeemLocationAddress
    };

    if (redeemLocation.city) {
      transformedRedeemLocation.city_id = redeemLocation.city.id;
    }

    return transformedRedeemLocation;
  });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Address',
      key: 'address',
      render: redeemLocation => {
        return redeemLocation.city && redeemLocation.country
          ? `${redeemLocation.address}, ${redeemLocation.city.name}, ${redeemLocation.country.name}`
          : redeemLocation.address;
      }
    },
    {
      title: 'QR',
      render: redeemLocation => (
        <div>
          <StyledSpan onClick={e => handleViewQR(e, redeemLocation.id)}>
            View
          </StyledSpan>
          <Divider type="vertical" />
          <StyledSpan onClick={e => handleDownloadQR(e, redeemLocation.id)}>
            Download
          </StyledSpan>
        </div>
      )
    },
    {
      title: 'Action',
      render: redeemLocation => (
        <span>
          <StyledIcon
            onClick={() => {
              setModalVisible(true);
              setModal('edit');
              setSelectedRedeemLocation(redeemLocation);
            }}
          >
            <Icon type="edit" />
          </StyledIcon>
          <Divider type="vertical" />
          <StyledIcon
            onClick={() => {
              setModalVisible(true);
              setModal('delete');
              setSelectedRedeemLocation(redeemLocation);
            }}
          >
            <Icon type="delete" />
          </StyledIcon>
        </span>
      )
    }
  ];

  const handleViewQR = (e, id) => {
    viewQR(id);
  };
  const handleDownloadQR = (e, id) => {
    downloadQR(id);
  };

  return (
    <Fragment>
      <StyledButtonWrapper>
        <StyledButton
          shape="circle"
          icon="plus"
          type="primary"
          onClick={() => {
            setModalVisible(true);
            setModal('create');
          }}
        ></StyledButton>
      </StyledButtonWrapper>
      <Table
        columns={columns}
        rowKey={redeemLocation => redeemLocation.id}
        dataSource={redeemLocations}
        pagination={{ pageSize: 10 }}
        scroll={{ x: 'max-content' }}
      />
      <CreateRedeemLocation
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
      />
      <EditRedeemLocation
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedRedeemLocation={selectedRedeemLocation}
      />
      <DeleteRedeemLocation
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        modal={modal}
        selectedRedeemLocation={selectedRedeemLocation}
      />
    </Fragment>
  );
};

export default RedeemLocationsList;
