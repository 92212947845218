import React, { useContext, useEffect, useState } from 'react';
import { Modal, Form, Input, Select } from 'antd';
import styled from 'styled-components';

import { RedeemLocationsContext } from '../../contexts/RedeemLocationsContext';

const { Option } = Select;

const StyledLocationWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const EditRedeemLocationForm = props => {
  const { cities, editRedeemLocation, response, setResponse } = useContext(
    RedeemLocationsContext
  );
  const {
    modalVisible,
    setModalVisible,
    modal,
    selectedRedeemLocation
  } = props;
  const [validationError, setValidationError] = useState({});
  const [loading, setLoading] = useState(false);

  const { getFieldDecorator, getFieldError } = props.form;

  const nameError = getFieldError('name') || validationError.name;
  const addressError = getFieldError('address') || validationError.address;
  const websiteError = getFieldError('website') || validationError.website;
  const cityError = getFieldError('city_id') || validationError.city_id;
  const latitudeError = getFieldError('lat') || validationError.lat;
  const longitudeError = getFieldError('lon') || validationError.lon;

  const closeModal = () => {
    setValidationError({});
    setModalVisible(false);
    props.form.resetFields();
  };

  const handleOk = () => {
    setLoading(true);

    props.form.validateFields((error, values) => {
      if (error) {
        setLoading(false);
        return;
      }

      editRedeemLocation(values, selectedRedeemLocation.id);
    });
  };

  const handleCancel = () => {
    setResponse({});
    setLoading(false);
    closeModal();
  };

  const handleChange = e => {
    const fieldName = e.target.name;
    const currentValidation = { ...validationError };
    currentValidation[fieldName] = undefined;
    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  const handleCities = () => {
    const currentValidation = { ...validationError };

    currentValidation.city_id = undefined;

    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  useEffect(() => {
    if (response.errors) {
      setValidationError(response.errors);
    } else {
      setValidationError({});
    }

    if (response.id) {
      closeModal();
    }

    if (Object.entries(response).length !== 0) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (modal === 'edit') {
    return (
      <Modal
        title={`Edit ${selectedRedeemLocation.name}`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <Form layout="vertical" onSubmit={handleOk}>
          <Form.Item
            label="Name"
            validateStatus={nameError ? 'error' : ''}
            help={nameError || ''}
          >
            {getFieldDecorator('name', {
              initialValue: selectedRedeemLocation.name,
              rules: [
                {
                  required: true,
                  message: 'Please input a redeem location name!'
                }
              ],
              onChange: e => handleChange(e)
            })(<Input name="name" placeholder="Name" />)}
          </Form.Item>
          <Form.Item
            label="Address"
            validateStatus={addressError ? 'error' : ''}
            help={addressError || ''}
          >
            {getFieldDecorator('address', {
              initialValue: selectedRedeemLocation.address,
              rules: [{ required: true, message: 'Please input an address!' }],
              onChange: e => handleChange(e)
            })(<Input name="address" placeholder="Street (and number)" />)}
          </Form.Item>
          <Form.Item
            label="City"
            validateStatus={cityError ? 'error' : ''}
            help={cityError || ''}
          >
            {getFieldDecorator('city_id', {
              initialValue: selectedRedeemLocation.city_id,
              rules: [{ required: true, message: 'Please select a city!' }],
              onChange: () => handleCities()
            })(
              <Select
                name="city_id"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                placeholder="City"
              >
                {cities.map(city => {
                  return (
                    <Option key={city.id} value={city.id}>
                      {`${city.name} (${city.postal_code})`}
                    </Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
          <StyledLocationWrapper>
            <Form.Item
              style={{ marginRight: '1rem' }}
              label="Latitude"
              validateStatus={latitudeError ? 'error' : ''}
              help={latitudeError || ''}
            >
              {getFieldDecorator('lat', {
                initialValue: selectedRedeemLocation.lat
                  ? selectedRedeemLocation.lat
                  : null,
                rules: [
                  {
                    required: true,
                    message: 'Please enter a location latitude!'
                  },
                  {
                    pattern: /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/,
                    message: 'Wrong format!'
                  }
                ],
                onChange: e => handleChange(e)
              })(<Input name="lat" placeholder="Latitude" maxLength={9} />)}
            </Form.Item>
            <Form.Item
              label="Longitude"
              validateStatus={longitudeError ? 'error' : ''}
              help={longitudeError || ''}
            >
              {getFieldDecorator('lon', {
                initialValue: selectedRedeemLocation.lon
                  ? selectedRedeemLocation.lon
                  : null,
                rules: [
                  {
                    required: true,
                    message: 'Please enter a location longitude!'
                  },
                  {
                    pattern: /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/,
                    message: 'Wrong format!'
                  }
                ],
                onChange: e => handleChange(e)
              })(<Input name="lon" placeholder="Longitude" maxLength={10} />)}
            </Form.Item>
          </StyledLocationWrapper>
          <Form.Item
            label="Website URL"
            validateStatus={websiteError ? 'error' : ''}
            help={websiteError || ''}
          >
            {getFieldDecorator('website', {
              initialValue: selectedRedeemLocation.website,
              rules: [
                {
                  pattern: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/,
                  message: 'The input is not a valid URL!'
                }
              ],
              onChange: e => handleChange(e)
            })(<Input name="website" placeholder="Website" />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  } else {
    return null;
  }
};

const EditRedeemLocation = Form.create()(EditRedeemLocationForm);

export default EditRedeemLocation;
