import React, { useContext, useEffect, useState } from 'react';
import { Modal, Form, Input, Upload, Button, Icon, Checkbox } from 'antd';

import { CountriesContext } from '../../contexts/CountriesContext';

// function hasErrors(fieldsError) {
//   return Object.keys(fieldsError).some(field => fieldsError[field]);
// }

const EditCountryForm = props => {
  const { editCountry, response, setResponse } = useContext(CountriesContext);
  const { modalVisible, setModalVisible, modal, selectedCountry } = props;
  const [validationError, setValidationError] = useState({});
  const [loading, setLoading] = useState(false);

  const { getFieldDecorator, getFieldError } = props.form;

  const nameError = getFieldError('name') || validationError.name;
  const isoError = getFieldError('iso') || validationError.iso;
  const flagError = getFieldError('flag') || validationError.flag;
  const visibleError = getFieldError('visible') || validationError.visible;

  const closeModal = () => {
    setValidationError({});
    setModalVisible(false);
    props.form.resetFields();
  };

  const handleOk = () => {
    setLoading(true);

    props.form.validateFields((error, values) => {
      if (error) {
        setLoading(false);
        return;
      }

      editCountry(values, selectedCountry.id);
    });
  };

  const handleCancel = () => {
    setResponse({});
    setLoading(false);
    closeModal();
  };

  const handleChange = e => {
    const currentValidation = { ...validationError };

    if (e.file) {
      currentValidation.image = undefined;
    } else {
      const eventTarget = e.target;

      if (eventTarget) {
        const fieldName = eventTarget.name;
        currentValidation[fieldName] = undefined;
      }
    }

    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  useEffect(() => {
    if (response.errors) {
      setValidationError(response.errors);
    } else {
      setValidationError({});
    }

    if (response.id) {
      closeModal();
    }

    if (Object.entries(response).length !== 0) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (modal === 'edit') {
    return (
      <Modal
        title={`Edit ${selectedCountry.name}`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
      >
        <Form layout="vertical" onSubmit={handleOk}>
          <Form.Item
            label="Name"
            validateStatus={nameError ? 'error' : ''}
            help={nameError || ''}
          >
            {getFieldDecorator('name', {
              initialValue: selectedCountry.name,
              rules: [
                { required: true, message: 'Please input a country name!' }
              ],
              onChange: e => handleChange(e)
            })(<Input name="name" placeholder="Name" />)}
          </Form.Item>
          <Form.Item
            label="ISO"
            validateStatus={isoError ? 'error' : ''}
            help={isoError || ''}
          >
            {getFieldDecorator('iso', {
              initialValue: selectedCountry.iso,
              rules: [
                { required: true, message: 'Please input an ISO code!' },
                { min: 2, message: 'ISO must be exactly 2 characters.' },
                { max: 2, message: 'ISO must be exactly 2 characters.' }
              ],
              onChange: e => handleChange(e)
            })(<Input name="iso" placeholder="ISO" maxLength={2} />)}
          </Form.Item>
          <Form.Item
            validateStatus={visibleError ? 'error' : ''}
            help={visibleError || ''}
          >
            {getFieldDecorator('visible', {
              initialValue: selectedCountry.visible,
              valuePropName: 'checked',
              onChange: e => handleChange(e)
            })(<Checkbox name="visible">Visible</Checkbox>)}
          </Form.Item>
          <Form.Item
            label="Flag image"
            validateStatus={flagError ? 'error' : ''}
            help={flagError || ''}
          >
            {getFieldDecorator('flag', {
              valuePropName: 'fileList',
              getValueFromEvent: normFile,
              onChange: e => handleChange(e)
            })(
              <Upload name="flag" beforeUpload={() => false}>
                <Button>
                  <Icon type="upload" /> Click to upload flag
                </Button>
              </Upload>
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  } else {
    return null;
  }
};

const EditCountry = Form.create()(EditCountryForm);

export default EditCountry;
